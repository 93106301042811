import * as React from 'react';
import {
  Datagrid,
  Edit,
  EditButton,
  EditProps,
  List,
  ListProps,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextField,
  Toolbar,
  maxValue,
  minValue,
} from 'react-admin';
import Icon from '@material-ui/icons/Settings';

export const SettingsIcon = Icon;

export const SettingsList: React.FC<ListProps> = (props) => (
  <List
    {...props}
    perPage={25}
    pagination={false}
    exporter={false}
    bulkActionButtons={false}
    title="System Settings"
  >
    <Datagrid>
      <TextField source="answer_radius" label="Answer radius (metres)" />
      <TextField source="minimum_moderation_rating" />
      <TextField source="current_show_id" />
      <EditButton />
    </Datagrid>
  </List>
);

export const SettingsEdit: React.FC<EditProps> = (props) => (
  <Edit {...props} title="System Settings (Edit)" undoable={false}>
    <SimpleForm toolbar={<SettingsEditToolbar />}>
      <NumberInput
        source="answer_radius"
        label="Answer radius (metres)"
        min={0}
        validate={[minValue(0)]}
      />
      <NumberInput
        source="minimum_moderation_rating"
        min={0}
        max={5}
        validate={[minValue(0), maxValue(5)]}
      />
    </SimpleForm>
  </Edit>
);

const SettingsEditToolbar: React.FC<typeof Toolbar.defaultProps> = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};
