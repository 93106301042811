import { useEffect } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { Title, useGetList, Loading, useNotify, useGetOne } from 'react-admin';
import { MapContainer } from 'react-leaflet';

import { config } from '../config';
import { Answer } from '../domain';

import { MapAnswerMarker, MapTileLayer } from '../components/maps';

export const Dashboard: React.FC = () => {
  const settings = useGetOne<{ id: string; current_show_id: string }>(
    'config',
    'config',
  );
  const current_show = settings.data ? settings.data.current_show_id : '';

  const { data, loading, error } = useGetList<Answer>(
    'accepted_answer',
    {
      // pagination
      page: 1,
      perPage: 5000,
    },
    {
      // sort
      field: 'id',
      order: 'asc',
    },
    {}, // filter
  );

  const notify = useNotify();
  useEffect(() => {
    if (error) notify(`Couldn't load map pins: ${error.message}`, 'error');
  }, [error]);

  const answers = data ? Object.values(data) : [];

  // Map starting state
  const zoom = config.defaultMapZoom;
  const position = config.defaultMapPosition;

  // prettier-ignore
  const mapInner
  = loading ? (<Loading />)
        : error ? null
        : answers.map((answer) => <MapAnswerMarker key={answer.id} answer={answer} />);

  return (
    <Card>
      <Title title={`Rider Spoke (Accepted Answers): ${current_show}`} />
      <CardContent>
        <MapContainer center={position} zoom={zoom} style={{ height: '80vh' }}>
          <MapTileLayer />
          {mapInner}
        </MapContainer>
        <Typography variant="subtitle1">
          This map shows all answers that may be shown to a participant in the
          current show. I.e. those that have been approved and have a rating
          above the minimum moderation rating (see System Settings).
        </Typography>
      </CardContent>
    </Card>
  );
};
