import * as React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import { FieldProps } from 'react-admin';

// Display shortened version of uuid (truncates at first `-`, which is probably
// unique enough for us)
export const UuidField: React.FC<FieldProps> = ({ source = '', record }) => (
  <Tooltip title={record?.[source] || ''}>
    <Typography variant="body2">
      {shortenUuid(record?.[source] || '')}
    </Typography>
  </Tooltip>
);

const shortenUuid = (value: string) => value.slice(0, 8);

// Display simple audio player
export const AudioField: React.FC<FieldProps> = ({ source = '', record }) => (
  <span>
    <audio src={record?.[source]} controls />
  </span>
);
