import * as React from 'react';
import { GeoJSON } from 'leaflet';
import { Marker, Popup, TileLayer } from 'react-leaflet';

import { Answer } from '../domain';

export const MapTileLayer: React.FC = () => (
  <TileLayer
    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
    subdomains="abcd"
    maxZoom={19}
  />
);

export const MapAnswerMarker: React.FC<{ answer: Answer }> = ({ answer }) => (
  <Marker
    key={answer.id}
    position={GeoJSON.coordsToLatLng(answer.location.coordinates)}
  >
    <Popup>
      <p>{answer.question_id}</p>
      <audio src={answer.processed_audio} controls />
    </Popup>
  </Marker>
);
