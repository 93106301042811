import { AuthProvider, fetchUtils } from 'react-admin';
import Keycloak, { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';

import { config } from './config';

const keycloakConfig: KeycloakConfig = {
  url: 'https://auth.services.blasttheory.com/auth',
  realm: 'Blast Theory Project Admin',
  clientId: config.keycloakClientId,
};

/**
 * An instance of Keycloak for use in the ReactKeycloakProvider.
 */
export const keycloak = Keycloak(keycloakConfig);
(window as typeof window & { keycloak: typeof keycloak }).keycloak = keycloak;

/**
 * Options for configuring the ReactKeycloakProvider.
 *
 * See https://www.keycloak.org/docs/latest/securing_apps/index.html#init-options
 */
export const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: 'login-required',
  enableLogging: true,
  checkLoginIframe: false,
};

/**
 * An AuthProvider for use with react-admin.
 *
 * NOTE: This doesn't really do the auth-stuff. We expect the entire app is
 * wrapped in a <ReactKeycloakProvider /> using the keycloak instance and
 * options exported above.  That will handle authentication separately.
 */
export const authProvider: AuthProvider = {
  login: async () => {},
  logout: async () => {
    await keycloak.logout();
  },
  checkAuth: async () => {
    if (!keycloak.authenticated) {
      throw new Error('NotAuthenticated');
    }
  },
  checkError: async (error) => {
    const status = error.status;
    if (status === 401) {
      throw new Error('BadAuth');
    }
  },
  getPermissions: async () => {},
  getIdentity: async () => {
    const profile = await keycloak.loadUserProfile();
    return {
      id: profile.id || 'unknown',
      fullName: `${profile.firstName || 'unknown'} ${profile.lastName}`,
      username: profile.username,
    };
  },
};

/**
 * HttpClient that attaches keycloak auth token as an Authorization header.
 *
 * Give this to the DataProvider to make authenticated requests to the api.
 */
export const httpClient: typeof fetchUtils.fetchJson = (url, options = {}) => {
  // Ensure headers is defined and is a Headers object for ease of use
  // prettier-ignore
  const headers
  = (!options.headers) ? new Headers({ Accept: 'application/json' })
  : (!(options.headers instanceof Headers)) ? new Headers(options.headers)
  : options.headers;

  const token = keycloak.token;
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  // FIXME
  // EMERGENCY HACK: The data-client shoves an id into the body where it's not
  // wanted, which breaks our api request permissions. This is a hack to rip the
  // id back out again. (Should just vendor the data-client and fix it -- or
  // write my own, cause that one is not well suited anymore.)
  if (options.method?.toUpperCase() === 'PATCH') {
    if (typeof options.body === 'string') {
      const body = JSON.parse(options.body);
      if (typeof body === 'object') {
        if (Object.keys(body).includes('id')) {
          delete body.id;
        }
      }
      options.body = JSON.stringify(body);
    }
  }

  return fetchUtils.fetchJson(url, { ...options, headers });
};
