import { useState } from 'react';
import {
  List,
  Datagrid,
  DateField,
  ListProps,
  TextField,
  useMutation,
  useNotify,
  ReferenceField,
  FieldProps,
} from 'react-admin';
import AllIcon from '@material-ui/icons/RecordVoiceOver';
import PendingIcon from '@material-ui/icons/VolumeUp';
import { GeoJSON } from 'leaflet';
import { MapContainer } from 'react-leaflet';

import { Answer } from '../domain';

import { MapTileLayer, MapAnswerMarker } from '../components/maps';
import { UuidField, AudioField } from '../components/fields';
import { DefaultPagination } from '../components/pagination';

export const AllAnswerIcon = AllIcon;
export const PendingAnswerIcon = PendingIcon;

export const AnswerList: React.FC<ListProps> = (props) => (
  <List
    {...props}
    perPage={25}
    pagination={<DefaultPagination />}
    exporter={false}
    sort={{ field: 'created_at', order: 'DESC' }}
  >
    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
    <Datagrid expand={<AnswerExpandDetails {...({} as any)} />}>
      <TextField source="show_id" />
      <UuidField source="id" />
      <ReferenceField
        label="Participant"
        source="participant_id"
        reference="participant"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="question_id" />
      <AudioField source="processed_audio" sortable={false} label="Answer" />
      <RatingEditField source="moderation_rating" label="Rating" />
      <DateField source="created_at" showTime={true} locales={['en-GB']} />
    </Datagrid>
  </List>
);

const RatingEditField: React.FC<FieldProps> = ({ source = '', record }) => {
  const [val, setVal] = useState<number | undefined>(record?.[source]);
  const notify = useNotify();
  const [mutate, { loading }] = useMutation();

  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (e) =>
    mutate(
      {
        type: 'update',
        resource: 'answer',
        payload: {
          id: record?.id,
          data: {
            id: record?.id, // See https://github.com/raphiniert-com/ra-data-postgrest/issues/21 (TODO remove when resolved)
            [source]: e.target.value === '' ? null : e.target.value,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          setVal(data[source]);
          notify('Rating updated');
        },
        onFailure: (error) =>
          notify(`Rating update error: ${error.message}`, 'warning'),
      },
    );

  return (
    <select value={val ?? ''} disabled={loading} onChange={handleChange}>
      <option value={''} disabled>
        --
      </option>
      <option value={0}>0</option>
      <option value={1}>1</option>
      <option value={2}>2</option>
      <option value={3}>3</option>
      <option value={4}>4</option>
      <option value={5}>5</option>
    </select>
  );
};

const AnswerExpandDetails: React.FC<{ record: Answer }> = ({ record }) => {
  const answer = record;
  const zoom = 14;
  const position = GeoJSON.coordsToLatLng(answer.location.coordinates);

  return (
    <MapContainer center={position} zoom={zoom} style={{ height: '40vh' }}>
      <MapTileLayer />
      <MapAnswerMarker answer={answer} />
    </MapContainer>
  );
};
