import * as React from 'react';
import {
  List,
  Datagrid,
  DateField,
  TextField,
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  ListControllerProps,
  Loading,
  ListProps,
  ShowProps,
} from 'react-admin';
import Icon from '@material-ui/icons/DirectionsRun';
import { GeoJSON } from 'leaflet';
import { MapContainer } from 'react-leaflet';

import { config } from '../config';
import { Answer } from '../domain';

import { MapTileLayer, MapAnswerMarker } from '../components/maps';
import { UuidField } from '../components/fields';
import { DefaultPagination } from '../components/pagination';

export const ParticipantIcon = Icon;

export const ParticipantList: React.FC<ListProps> = (props) => (
  <List
    {...props}
    perPage={25}
    pagination={<DefaultPagination />}
    exporter={false}
    sort={{ field: 'created_at', order: 'DESC' }}
  >
    <Datagrid expand={<ParticipantExpandDetails />}>
      <TextField source="show_id" />
      <UuidField source="id" />
      <TextField source="name" />
      <DateField source="created_at" showTime={true} locales={['en-GB']} />
    </Datagrid>
  </List>
);

const ParticipantExpandDetails: React.FC<ShowProps> = (props) => (
  <Show {...props} title=" ">
    <SimpleShowLayout>
      <ReferenceManyField
        reference="answer"
        target="participant_id"
        addLabel={false}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <ParticipantAnswerMap {...({} as any)} />
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

const ParticipantAnswerMap: React.FC<ListControllerProps<Answer>> = (props) => {
  const { data, error, loading } = props;
  const answers = data ? Object.values(data) : [];

  // Map starting state
  const zoom = 14;
  const position =
    answers.length > 0
      ? GeoJSON.coordsToLatLng(answers[0]!.location.coordinates) // eslint-disable-line @typescript-eslint/no-non-null-assertion
      : config.defaultMapPosition;

  // prettier-ignore
  const mapInner
  = loading ? (<Loading />)
        : error ? null
        : answers.map((answer) => <MapAnswerMarker key={answer.id} answer={answer} />);

  return (
    <MapContainer center={position} zoom={zoom} style={{ height: '40vh' }}>
      <MapTileLayer />
      {mapInner}
    </MapContainer>
  );
};
