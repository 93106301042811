import { render } from 'react-dom';
import { Admin, Resource, Loading } from 'react-admin';
import postgrestClient from '@raphiniert/ra-data-postgrest';
import L from 'leaflet';

import { config } from './config';

import { Dashboard } from './pages/dashboard';
import { AnswerList, AllAnswerIcon, PendingAnswerIcon } from './pages/answer';
import { ParticipantList, ParticipantIcon } from './pages/participant';
import { SettingsEdit, SettingsList, SettingsIcon } from './pages/settings';

import {
  authProvider,
  httpClient,
  keycloak,
  keycloakInitOptions,
} from './auth';

// https://github.com/react-keycloak/react-keycloak/blob/master/packages/web/README.md
import { ReactKeycloakProvider } from '@react-keycloak/web';

// Enable parcel to play nice with leaflet default image-urls
L.Marker.prototype.options.icon = new L.Icon({
  iconUrl: require('url:leaflet/dist/images/marker-icon.png'),
  iconRetinaUrl: require('url:leaflet/dist/images/marker-icon-2x.png'),
  shadowUrl: require('url:leaflet/dist/images/marker-shadow.png'),
});

render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={keycloakInitOptions}
    LoadingComponent={
      <Loading loadingPrimary="Rider Spoke" loadingSecondary="Admin" />
    }
  >
    <Admin
      dashboard={Dashboard}
      authProvider={authProvider}
      dataProvider={postgrestClient(config.apiUrl, httpClient)}
    >
      <Resource
        name="pending_answer"
        options={{ label: 'Pending Moderation' }}
        icon={PendingAnswerIcon}
        list={AnswerList}
      />

      <Resource
        name="answer"
        options={{ label: 'All Answers' }}
        icon={AllAnswerIcon}
        list={AnswerList}
      />

      <Resource
        name="participant"
        icon={ParticipantIcon}
        list={ParticipantList}
      />

      <Resource
        name="config"
        options={{ label: 'System Settings' }}
        icon={SettingsIcon}
        edit={SettingsEdit}
        list={SettingsList}
      />

      {/* Note: This is not a listed-resource, but we use it in the dashboard so
          need to specify it as a resource to ensure the redux-selector stuff works
          and useGetList actually gives us our data back! */}
      <Resource name="accepted_answer" />
    </Admin>
  </ReactKeycloakProvider>,
  document.getElementById('root'),
);
